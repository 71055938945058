import propertyApi from "@/api/property.js";
import { i18n } from "@/plugins/i18n";

const state = {
  properties: [],
  property: {},
  isLoading: false,
  isCreating: false,
  isUploading: false,
  isUpdating: false,
  isLoadingRega: false,
  isLoadingRefreshRega: false,
  errors: [],
  regaQr: {}
};

const mutations = {
  getPropertiesStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getPropertiesSuccess(state, payload) {
    state.isLoading = false;
    state.properties = payload;
    state.errors = [];
  },
  getPropertiesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  createPropertyStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createPropertySuccess(state) {
    state.isCreating = false;
    state.errors = [];
  },
  createPropertyFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  updatePropertyStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updatePropertySuccess(state, payload) {
    state.isUpdating = false;
    state.property = payload;
    state.errors = [];
  },
  updatePropertyFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  addMediaStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addMediaSuccess(state, payload) {
    state.isUploading = false;
    state.errors = [];
    state.property = payload;
  },
  addMediaFailed(state) {
    state.isUploading = false;
    state.errors = [];
  },

  addInfoMediaStart(state) {
    state.isUploading = true;
    state.errors = [];
  },
  addInfoMediaSuccess(state, payload) {
    state.isUploading = false;
    state.errors = [];
    state.property = payload;
  },
  addInfoMediaFailed(state) {
    state.isUploading = false;
    state.errors = [];
  },

  removePropertyAdditionalFile(state, payload) {
    state.property = {
      ...state.property,
      file_attachments: state.property.file_attachments.filter(
        (file) => file.id !== payload
      )
    };
  },

  removePropertyDesignFile(state, payload) {
    state.property = {
      ...state.property,
      design_attachments: state.property.design_attachments.filter(
        (file) => file.id !== payload
      )
    };
  },

  removePropertyMediaFile(state, payload) {
    state.property = {
      ...state.property,
      media_attachments: state.property.media_attachments.filter(
        (file) => file.id !== payload
      )
    };
  },

  getPropertyStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getPropertySuccess(state, payload) {
    state.isLoading = false;
    state.property = payload;
    state.errors = [];
  },
  getPropertyFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updatePropertyStatusStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updatePropertyStatusSuccess(state, payload) {
    state.isUpdating = false;
    state.property = payload;
    state.errors = [];
  },
  updatePropertyStatusFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  refreshStart(state) {
    state.isLoadingRefreshRega = true
  },
  refreshSuccess(state, payload) {
    state.isLoadingRefreshRega = false
    state.property = payload
    state.errors = [];
  },
  refreshFailed(state, error) {
    state.isLoadingRefreshRega = false
    state.errors = error
  },
  writePropertyRegaQr(state, payload) {
    state.regaQr = payload;
  }
};
const actions = {
  async getProperties(context, payload) {
    try {
      context.commit("getPropertiesStart");

      const res = await propertyApi.getProperties(payload);
      context.commit("getPropertiesSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getPropertiesFailed", e.response.data.error);

      return false;
    }
  },

  async returnProperty(context, payload) {
    try {
      return await propertyApi.getProperty(payload);
    } catch (e) {
      return false;
    }
  },

  async getProperty(context, payload) {
    try {
      context.commit("getPropertyStart");
      const res = await propertyApi.getProperty(payload);

      context.commit("getPropertySuccess", res.data);

      return true;
    } catch (e) {
      context.commit("getPropertyFailed", e.response.data.error);

      return false;
    }
  },

  async getPropertyWithoutLoader(context, payload) {
    try {
      const res = await propertyApi.getProperty(payload);

      context.commit("getPropertySuccess", res.data);

      return true;
    } catch (e) {

      return false;
    }
  },

  async updateProperty(context, payload) {
    try {
      context.commit("updatePropertyStart");

      let res = {};

      if (!payload?.step) {
        res = await propertyApi.updateProperty(payload);
      }

      if (payload?.step === 'general') {
        res = await propertyApi.updatePropertyGeneral(payload);
      }

      if (payload?.step === 'constraints') {
        res = await propertyApi.updatePropertyConstraints(payload);
      }

      if (payload?.step === 'utilities') {
        res = await propertyApi.updatePropertyUtilities(payload);
      }

      if (payload?.step === 'address') {
        res = await propertyApi.updatePropertyAddress(payload);
      }

      if (payload?.step === 'other') {
        res = await propertyApi.updatePropertyOther(payload);
      }

      context.commit("updatePropertySuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updatePropertyFailed", e.response.data.error);

      return false;
    }
  },

  async removeProperty(context, property_number) {
    try {
      await propertyApi.removeProperty(property_number)
      return true;
    } catch (e) {
      return false
    }
  },

  async updatePropertyStatus(context, payload) {
    try {
      context.commit("updatePropertyStatusStart");

      const res = await propertyApi.updatePropertyStatus(payload);
      context.commit("updatePropertyStatusSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updatePropertyStatusFailed", e.response.data.error);

      return false;
    }
  },

  async createProperty(context, payload) {
    try {
      context.commit("createPropertyStart");

      const res = await propertyApi.createProperty(payload);
      context.commit("createPropertySuccess");

      return res.data.number;
    } catch (e) {
      context.commit("createPropertyFailed", e.response.data.error);

      return false;
    }
  },
  async addPropertyMedia(context, { id, filesData }) {
    try {
      context.commit("addMediaStart");
      const requests = filesData.map((file) => propertyApi.addMedia(id, file));
      const res = await Promise.all(requests);
      context.commit("addMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaFailed", e);
      return false;
    }
  },

  async addPropertyMediaWithError(context, { id, filesData }) {
    console.log(filesData);
    console.log(id);
    try {
      context.commit("addMediaStart");
      const requests = filesData.map((file) => propertyApi.addMedia(id, file).catch(() => {
        const error = new Error('file err')
        error.type = 'file__err'
        error.data = {file: file}
        throw error
      }));
      const res = await Promise.all(requests);
      context.commit("addMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addMediaFailed")
      if (e.type === 'file__err') {
        throw e
      }
    }
  },

  async addPropertyInfoMedia(context, { id, filesData }) {
    try {
      context.commit("addInfoMediaStart");
      const requests = filesData.map((file) => propertyApi.addMedia(id, file));
      const res = await Promise.all(requests);
      context.commit("addInfoMediaSuccess", res[res.length - 1].data);
      return res[res.length - 1].data;
    } catch (e) {
      context.commit("addInfoMediaFailed", e.response.data.error);
      return false;
    }
  },
  async removePropertyFile(context, { propertyId, fileId }) {
    try {
      await propertyApi.removeFile(propertyId, fileId);
      return true;
    } catch (e) {
      return false;
    }
  },
  async removePropertyFiles(context, { id, files }) {
    try {
      const requests = files.map((file) => propertyApi.removeFile(id, file.id));
      return await Promise.all(requests);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async createPropertyRega(context, data) {
    try {
      context.commit("createPropertyStart");
      const res = await propertyApi.createPropertyRega(data);
      context.commit("createPropertySuccess");
      return res.data.number;
    } catch (e) {
      context.commit("createPropertyFailed", e.response.data.error);
      return false;
    }
  },
  async getPropertyRegaQr(context, propertyNumber) {
    try {
      const res = await propertyApi.getRegaQr(propertyNumber)
      context.commit("writePropertyRegaQr", res.data)
      return res
    } catch (e) {
      return false;
    }
  },

  async resetPropertyRegaQr(context) {
    context.commit("writePropertyRegaQr", {});
  },
  async refreshRega(context, { id, data }) {
    try {
      context.commit("refreshStart")
      const res = await propertyApi.refreshRega(id, data)
      context.commit("refreshSuccess", res.data)
      return res
    } catch (e) {
      context.commit("refreshFailed", e.response.data.error)
      return false;
    }
  }
};

const getters = {
  propertyLocationArea({ property }) {
    return (property?.land_area_polygon || []).map((area) => {
      return {
        lat: area?.latitude || 0,
        lng: area?.longitude || 0
      };
    });
  },
  propertyTitleErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "title__required") && result.push(i18n.t("errors.titleRequired"));
    errors.find((item) => item === "title__invalid") && result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  propertyTypeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "type__required") && result.push(i18n.t("errors.typeRequired"));
    errors.find((item) => item === "type__invalid") && result.push(i18n.t("errors.typeInvalid"));
    errors.find((item) => item === "nhc_property_type__required") && result.push(i18n.t("errors.typeRequired"));
    errors.find((item) => item === "nhc_property_type__invalid") && result.push(i18n.t("errors.typeInvalid"));
    return result;
  },
  propertyPriceErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "price__required") && result.push(i18n.t("errors.priceRequired"));
    errors.find((item) => item === "price__invalid") && result.push(i18n.t("errors.priceInvalid"));
    return result;
  },

  propertyDescriptionErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "description__invalid") && result.push(i18n.t("errors.descriptionInvalid"));
    return result;
  },

  propertyNotesErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_notes__required") && result.push(i18n.t("errors.notesRequired"));
    errors.find((item) => item === "nhc_notes__invalid") && result.push(i18n.t("errors.notesInvalid"));
    return result;
  },

  propertyDeedTypeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_title_deed_type__invalid") && result.push(i18n.t("errors.deedTypeInvalid"));
    return result;
  },

  propertyDeedNumberErrors({errors}) {
    const result = [];
    errors.find((item) => item === "deed_number__invalid") && result.push(i18n.t("errors.deedNumberInvalid"));
    errors.find((item) => item === "deed_number__required") && result.push(i18n.t("errors.deedNumberRequired"));
    return result;
  },

  propertyBuildingStatusErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_status__invalid") && result.push(i18n.t("errors.buildingStatusInvalid"));
    return result;
  },

  propertyLandTotalPriceErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_total_price__invalid") && result.push(i18n.t("errors.landTotalPriceInvalid"));
    return result;
  },

  propertyLandAnnualRentErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_total_annual_rent__invalid") && result.push(i18n.t("errors.landAnnualRentInvalid"));
    return result;
  },

  propertyAgeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_age__invalid") && result.push(i18n.t("errors.ageInvalid"));
    return result;
  },

  propertyPurposeErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_usage__invalid") && result.push(i18n.t("errors.purposeInvalid"));
    return result;
  },

  propertyFaceErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_property_face__invalid") && result.push(i18n.t("errors.faceInvalid"));
    return result;
  },

  propertyStreetWidthErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_street_width__invalid") && result.push(i18n.t("errors.streetWidthInvalid"));
    return result;
  },


  propertyPlanNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_street_width__invalid") && result.push(i18n.t("errors.streetWidthInvalid"));
    return result;
  },

  propertyLandNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_land_number__invalid") && result.push(i18n.t("errors.landNumberInvalid"));
    return result;
  },


  propertyBedroomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "bedrooms__required") &&
      result.push(i18n.t("errors.bedroomsRequired"));
    errors.find((item) => item === "bedrooms__invalid") &&
      result.push(i18n.t("errors.bedroomsInvalid"));
    return result;
  },
  propertyBathroomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "bathrooms__required") &&
      result.push(i18n.t("errors.bathroomsRequired"));
    errors.find((item) => item === "bathrooms__invalid") &&
      result.push(i18n.t("errors.bathroomsInvalid"));
    return result;
  },
  propertyLivingRoomsErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "living_rooms__required") &&
      result.push(i18n.t("errors.livingRoomsRequired"));
    errors.find((item) => item === "living_rooms__invalid") &&
      result.push(i18n.t("errors.livingRoomsInvalid"));
    return result;
  },
  propertyKitchenTypeErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "kitchen_type__required") &&
      result.push(i18n.t("errors.kitchenTypeRequired"));
    errors.find((item) => item === "kitchen_type__invalid") &&
      result.push(i18n.t("errors.kitchenTypeInvalid"));
    return result;
  },
  totalAreaErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "total_area__required") &&
      result.push(i18n.t("errors.totalAreaRequired"));
    errors.find((item) => item === "total_area__invalid") &&
      result.push(i18n.t("errors.totalAreaInvalid"));
    return result;
  },
  livingAreaErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "living_area__required") &&
      result.push(i18n.t("errors.livingAreaRequired"));
    errors.find((item) => item === "living_area__invalid") &&
      result.push(i18n.t("errors.livingAreaInvalid"));
    return result;
  },
  propertyCityErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "city__required") &&
      result.push(i18n.t("errors.cityRequired"));
    errors.find((item) => item === "city__invalid") &&
      result.push(i18n.t("errors.cityInvalid"));
    errors.find((item) => item === "nhc_city__required") &&
      result.push(i18n.t("errors.cityRequired"));
    errors.find((item) => item === "nhc_city__invalid") &&
      result.push(i18n.t("errors.cityInvalid"));
    return result;
  },

  floorErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "floor__required") &&
      result.push(i18n.t("errors.floorRequired"));
    errors.find((item) => item === "floor__invalid") &&
      result.push(i18n.t("errors.floorInvalid"));
    return result;
  },
  floorsTotalErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "floors_total__required") &&
      result.push(i18n.t("errors.floorsTotalRequired"));
    errors.find((item) => item === "floors_total__invalid") &&
      result.push(i18n.t("errors.floorsTotalInvalid"));
    return result;
  },
  propertyPeriodErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "reservation_period__required") &&
      result.push(i18n.t("errors.periodRequired"));
    errors.find((item) => item === "reservation_period__invalid") &&
      result.push(i18n.t("errors.periodInvalid"));
    return result;
  },
  propertyReservationTypeErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "reservation_value_type__required") &&
      result.push(i18n.t("errors.reservationTypeRequired"));
    errors.find((item) => item === "reservation_value_type__invalid") &&
      result.push(i18n.t("errors.reservationTypeInvalid"));
    return result;
  },
  propertyReservationValueErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "reservation_value__required") && result.push(i18n.t("errors.reservationValueRequired"));
    errors.find((item) => item === "reservation_value__invalid") && result.push(i18n.t("errors.reservationValueInvalid"));
    return result;
  },

  propertyNhcRegionErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_region__required") &&
      result.push(i18n.t("errors.regionInvalid"));
    errors.find((item) => item === "src__invalid") &&
      result.push(i18n.t("errors.regionInvalid"));
    return result;
  },

  propertyNhcDistrictErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_district__required") &&
      result.push(i18n.t("errors.districtRequired"));
    errors.find((item) => item === "nhc_district__invalid") &&
      result.push(i18n.t("errors.districtInvalid"));
    return result;
  },

  propertyNhcCityErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_city__required") &&
      result.push(i18n.t("errors.cityRequired"));
    errors.find((item) => item === "nhc_city__invalid") &&
      result.push(i18n.t("errors.cityInvalid"));
    return result;
  },

  propertyAddressErrors: function({ errors }) {
    const result = [];
    errors.find((item) => item === "address__required") &&
      result.push(i18n.t("errors.addressRequired"));
    errors.find((item) => item === "address__invalid") &&
      result.push(i18n.t("errors.addressInvalid"));
    return result;
  },

  propertyGuaranteesErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_guarantees__required") && result.push(i18n.t("errors.guaranteesRequired"));
    errors.find((item) => item === "nhc_guarantees__invalid") && result.push(i18n.t("errors.guaranteesInvalid"));
    return result;
  },

  propertyAdvertisementIdErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "advertisement_id__required") &&
      result.push(i18n.t("errors.advertisement_id__required"));
    errors.find((item) => item === "advertisement_id__invalid") &&
      result.push(i18n.t("errors.advertisement_id__invalid"));
    errors.find((item) => item === "advertisement_id__expired") &&
      result.push(i18n.t("errors.advertisement_id__expired"));

    errors.find((item) => item === "ad_license_number__required") &&
    result.push(i18n.t("errors.ad_license_number__required"));
    errors.find((item) => item === "ad_license_number__invalid") &&
    result.push(i18n.t("errors.ad_license_number__invalid"));
    errors.find((item) => item === "ad_license_number__expired") &&
    result.push(i18n.t("errors.ad_license_number__expired"));
    return result;
  },

  propertyBuildingDateErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_date__invalid") && result.push(i18n.t("errors.buildingDateInvalid"));
    return result;
  },

  propertyUnitLandAreaErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "land_area__invalid") && result.push(i18n.t("errors.landAreaInvalid"));
    return result;
  },

  propertyUnitBuildingAreaErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "building_area__invalid") && result.push(i18n.t("errors.buildingAreaInvalid"));
    return result;
  },

  propertyAuthorizationNumberErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "authorization_number__invalid") && result.push(i18n.t("errors.authorizationNumberInvalid"));
    return result;
  },

  propertyOwnerPhoneErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "owner_phone_code__invalid") && result.push(i18n.t("errors.ownerPhoneCodeInvalid"));
    errors.find((item) => item === "owner_phone_code__required") && result.push(i18n.t("errors.ownerPhoneCodeRequired"));
    errors.find((item) => item === "owner_phone_number__invalid") && result.push(i18n.t("errors.ownerPhoneInvalid"));
    errors.find((item) => item === "owner_phone_number__required") && result.push(i18n.t("errors.ownerPhoneRequired"));
    errors.find((item) => item === "owner_phone__invalid") && result.push(i18n.t("errors.ownerPhoneInvalid"));
    errors.find((item) => item === "owner_phone__required") && result.push(i18n.t("errors.ownerPhoneRequired"));
    return result;
  },

  propertyObligationsErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_obligations__required") && result.push(i18n.t("errors.obligationsRequired"));
    errors.find((item) => item === "nhc_obligations__invalid") && result.push(i18n.t("errors.obligationsInvalid"));
    return result;
  },

  propertyIsConstrainedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_constrained__required") && result.push(i18n.t("errors.isConstrainedRequired"));
    errors.find((item) => item === "nhc_is_constrained__invalid") && result.push(i18n.t("errors.isConstrainedInvalid"));
    return result;
  },

  propertyIsPawnedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_pawned__required") && result.push(i18n.t("errors.isPawnedRequired"));
    errors.find((item) => item === "nhc_is_pawned__invalid") && result.push(i18n.t("errors.isPawnedInvalid"));
    return result;
  },

  propertyIsHaltedErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_halted__required") && result.push(i18n.t("errors.isHaltedRequired"));
    errors.find((item) => item === "nhc_is_halted__required") && result.push(i18n.t("errors.isHaltedInvalid"));
    return result;
  },

  propertyIsTestamentErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "nhc_is_testment__required") && result.push(i18n.t("errors.isTransferredRequired"));
    errors.find((item) => item === "nhc_is_testment__invalid") && result.push(i18n.t("errors.isTransferredInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};

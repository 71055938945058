<template>
  <div class="blue menu pt-4">
    <div class="d-flex mb-5 ps-5">
      <v-img
        max-width="100"
        height="56"
        :src="require('@/assets/icons/logo-white.svg')"
      />
    </div>
    <v-list flat color="transparent" class="pt-4">
      <v-list-item-group>
        <v-list-item
          v-for="item in menuList"
          :key="item.title"
          link
          :to="`/${item.link}`"
          class="font-weight-medium mx-5 my-1"
          active-class="menu-item--active"
        >
          <v-list-item-icon class="my-4 me-3">
            <v-img
              height="20"
              max-width="20"
              :src="getImage(item.icon)"
              class="icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="white--text menu__item-title">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          title: this.$tc("dashboard.title", 1),
          link: "dashboard",
          icon: "dashboard",
          disabled: [],
        },
        {
          title: this.$tc("project.title", 2),
          link: "projects",
          icon: "project",
          disabled: [],
        },
        {
          title: this.$tc("property.title", 2),
          link: "properties",
          icon: "unit",
          disabled: [],
        },
        {
          title: this.$tc("reservation.title", 2),
          link: "reservations",
          icon: "request",
          disabled: [],
        },
        // {
        //   title: this.$tc("staff.title", 2),
        //   link: "staff",
        //   icon: "staff",
        //   disabled: ["manager"],
        // },
        {
          title: this.$t("agents.title"),
          link: "agents",
          icon: "agents",
          disabled: ["manager"],
        },
        {
          title: this.$t("agency.title"),
          link: "agency-details",
          icon: "settings",
          disabled: [],
        },
        // {
        //   title: this.$t("website.title"),
        //   link: "website",
        //   icon: "website",
        //   disabled: [],
        // },
      ],
    };
  },

  mounted() {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    this.$i18n.locale = lang;
    this.$vuetify.locale = lang;
    this.$vuetify.rtl = lang == 'ar';
  },

  methods: {
    getImage(icon) {
      return require(`@/assets/icons/${icon}.svg`);
    },
  },

  computed: {
    ...mapState({
      role: (state) => state.profile.profile.role,
    }),
    menuList() {
      return this.items.filter((item) => !item.disabled.includes(this.role));
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  inset-inline-start: 0;
  top: 0;
  height: 100vh;
  width: 220px;
  z-index: 1;
  @media screen and (min-width: 1440px) {
    width: 240px;
  }

  & .v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 8px;
    transition: 0.2s background;
  }

  &__item-title {
    z-index: 1;
  }
}

.menu-item--active {
  background: #7ab845;

  & .menu__item-title {
    color: white;
  }
}

.items-group {
  &__title {
    color: rgba(255, 255, 255, 0.8);
  }

  &__line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>

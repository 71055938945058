// import agencyApi from "@/api/agency";
// import { i18n } from "@/plugins/i18n";
import axios from "@/plugins/axios";

const state = {
  isLoading: false,
  isLoadingAction: false,
  isLoadingGeneral: false,
  site: {},
  errors: [],
};

const mutations = {
  getSiteStart(state) {
    state.isLoading = true;
    state.site = {};
    state.errors = [];
  },
  getSiteSuccess(state, site) {
    state.isLoading = false;
    state.site = site;
  },
  getSiteFailed(state, errors) {
    state.isLoading = false;
    state.errors = errors;
  },

  sendActionStart(state) {
    state.isLoadingAction = true;
    state.errors = [];
  },
  sendActionSuccess(state, site) {
    state.isLoadingAction = false;
    state.site = site;
  },
  sendActionFailed(state, errors) {
    state.isLoadingAction = false;
    state.errors = errors;
  },

  updateSiteGeneralStart(state) {
    state. isLoadingGeneral = true;
    state.errors = [];
  },

  updateSiteGeneralSuccess(state, site) {
    state.isLoadingGeneral = false;
    state.site = site;
  },

  updateSiteGeneralFailed(state, errors) {
    state.isLoadingGeneral = false;
    state.errors = errors;
  },

};

const actions = {
  async getSite(context) {
    try {
      context.commit("getSiteStart");

      const res = await axios.get("/agency/api/v1/site");
      context.commit("getSiteSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getSiteFailed", e.response?.data.error);
      return false;
    }
  },

  async sendAction(context, action) {
    try {
      context.commit("sendActionStart");

      const res = await axios.post("/agency/api/v1/site/action", {
        action: action,
      });
      context.commit("sendActionSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("sendActionFailed", e.response?.data.error);
      return false;
    }
  },

  async updateSiteGeneral(context, data) {
    try {
      context.commit("updateSiteGeneralStart");

      const res = await axios.post("/agency/api/v1/site/general", data);
      context.commit("updateSiteGeneralSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateSiteGeneralFailed", e.response?.data.error);
      return false;
    }
  }
};

const getters = {

};

export default {
  state,
  mutations,
  actions,
  getters,
};

import axios from "@/plugins/axios";

const urlPath = "/agency/api/v1/projects/";

const getUnitsList = (id, data) => {
  return axios.get(`${urlPath}${id}/units/?${data}`);
};

const getAllUnits = ({ id, params }) => {
  return axios.get(`${urlPath}${id}/units/all?${params}`);
};

const createUnitItem = (id, data) => {
  return axios.post(`${urlPath}${id}/units/`, data);
};

const getUnitItemRegaQr = ({ project_number, unit_id }) => {
  return axios.get(`${urlPath}${project_number}/units/${unit_id}/rega-qr`);
};

const getUnitItem = ({ project_number, unit_id }) => {
  return axios.get(`${urlPath}${project_number}/units/${unit_id}`);
};

const createUnitRega = (project_number, data) => {
  return axios.post(`${urlPath}${project_number}/units/rega`, data);
};

const updateUnitItem = (id, uId, data) => {
  return axios.put(`${urlPath}${id}/units/${uId}`, data);
};

const updateUnitItemConstraints = (id, uId, data) => {
  return axios.put(`${urlPath}${id}/units/${uId}/constraints`, data);
};

const updateUnitItemAddress = (id, uId, data) => {
  return axios.put(`${urlPath}${id}/units/${uId}/address`, data);
};

const removeUnitItem = (id, uId) => {
  return axios.delete(`${urlPath}${id}/units/${uId}`);
};

const addUnitItemFile = (id, uId, data) => {
  return axios.post(`${urlPath}${id}/units/${uId}/attachments/`, data);
};

const removeUnitItemFile = (id, uId, fileId) => {
  return axios.delete(`${urlPath}${id}/units/${uId}/attachments/${fileId}`);
};

const orderUnitItemFile = (id, uId, fileId, data) => {
  return axios.post(
    `${urlPath}${id}/units/${uId}/attachments/${fileId}/order`,
    data
  );
};

const actionUnitItem = (id, uId, data) => {
  return axios.post(`${urlPath}${id}/units/${uId}/action`, data);
};

const regaRefreshUnit = (id, uId, data) => {
  return axios.post(`${urlPath}${id}/units/${uId}/rega`, data);
}

export default {
  getUnitsList,
  createUnitItem,
  getUnitItem,
  updateUnitItem,
  removeUnitItem,
  addUnitItemFile,
  removeUnitItemFile,
  orderUnitItemFile,
  actionUnitItem,
  getAllUnits,
  getUnitItemRegaQr,
  regaRefreshUnit,
  createUnitRega,
  updateUnitItemConstraints,
  updateUnitItemAddress
};
